var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "date-check" },
    [
      _c("span", { staticClass: "ml-2" }, [_vm._v(_vm._s(_vm.title) + ":")]),
      _vm.error == 1
        ? _c("small", { staticClass: "ml-2", staticStyle: { color: "red" } }, [
            _vm._v("(" + _vm._s(_vm.dateError) + ")"),
          ])
        : _vm.error == 0
        ? _c("small", { staticClass: "ml-2" })
        : _c(
            "small",
            { staticClass: "ml-2", staticStyle: { color: "green" } },
            [_vm._v(" Tamamlandı")]
          ),
      _c("imask-input", {
        directives: [
          {
            name: "my-tooltip",
            rawName: "v-my-tooltip.hover",
            modifiers: { hover: true },
          },
        ],
        staticClass: "form-control mt-2",
        attrs: {
          mask: "00-00-0000 00:00",
          lazy: false,
          radix: "",
          unmask: false,
          outlined: "",
          dense: "",
          title: "Örnek: 13-08-1999 10:00",
        },
        model: {
          value: _vm.getCampaignDate,
          callback: function ($$v) {
            _vm.getCampaignDate = $$v
          },
          expression: "getCampaignDate",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="date-check">
    <span class="ml-2">{{ title }}:</span>
    <small style="color: red" class="ml-2" v-if="error == 1"
      >({{ dateError }})</small
    >
    <small class="ml-2" v-else-if="error == 0"></small>
    <small style="color: green" class="ml-2" v-else> Tamamlandı</small>

    <imask-input
      v-model="getCampaignDate"
      class="form-control mt-2"
      mask="00-00-0000 00:00"
      :lazy="false"
      radix=""
      :unmask="false"
      outlined
      dense
      v-my-tooltip.hover
      title="Örnek: 13-08-1999 10:00"
    />
  </div>
</template>
<script>
import { IMaskComponent } from "vue-imask";
import moment from "moment";

export default {
  name: "Datechecker",
  components: {
    "imask-input": IMaskComponent,
  },
  props: ["title", "date", "dateType", "resType"],
  data() {
    return {
      campaignDate: "",
      dateError: "",
      error: 0,
    };
  },
  computed: {
    getCampaignDate: {
      get(val) {
        //format date
        const x =
          this.date == "" ? "" : moment(this.date).format("DD-MM-yyyy HH:mm");
        this.checkDateTimeValid(x);
        return x;
      },
      set(val) {
        if (val !== "__-__-____ __:__") {
          this.checkDateTimeValid(val);
        } else {
          this.error = 0;
        }
      },
    },
  },
  watch: {
    date: {
      handler(val) {
        this.campaignDate =
          this.date == "" ? "" : moment(this.date).format("DD-MM-yyyy HH:mm");
      },
      immediate: true,
    },
  },
  methods: {
    dayChecker(day) {
      return day.length < 2 || parseInt(day) > 31 || parseInt(day) <= 0;
    },
    monthChecker(month) {
      return month.length < 2 || parseInt(month) > 12 || parseInt(month) <= 0;
    },
    yearChecker(year) {
      return year.length < 4 || parseInt(year) > 2100 || parseInt(year) < 2022;
    },
    hourChecker(hour) {
      return hour.length < 2 || parseInt(hour) < 0 || parseInt(hour) > 24;
    },
    minuteChecker(minute) {
      return minute.length < 2 || parseInt(minute) > 59 || parseInt(minute) < 0;
    },
    checkDateTimeValid(val) {
      try {
        if (val.length > 0) {
          let [day, month, yearTime] = val.replaceAll("_", "").split("-");
          let year_hours = yearTime.split(" ");
          let hours = year_hours[1];
          let splittedTime = hours.split(":");
          let hour = splittedTime[0];
          let minute = splittedTime[1];
          let year = yearTime.split(" ")[0];

          let resDate = [this.resType, this.dateType].join(".");
          if (this.dayChecker(day)) {
            this.error = 1;
            this.dateError =
              "Girdiğiniz gün formatı hatalı 01-31 arası değer giriniz.";
          } else if (this.monthChecker(month)) {
            this.error = 1;
            this.dateError = "Girdiğiniz ay hatalı";
          } else if (this.yearChecker(year)) {
            this.error = 1;
            this.dateError = "Girdiğiniz yıl hatalı";
          } else if (this.hourChecker(hour)) {
            this.error = 1;
            this.dateError = "Girdiğiniz saat hatalı";
          } else if (this.minuteChecker(minute)) {
            this.error = 1;
            this.dateError = "Girdiğiniz dakika hatalı";
          } else {
            this.error = -1;
            this.dateError = "ok";
            [
              "create.start",
              "create.end",
              "pickup.start",
              "pickup.end",
              "dropoff.start",
              "dropoff.end",
            ].forEach((item) => {
              if (resDate === item) {
                this.$store.state.campaignDates[this.resType][this.dateType] =
                  moment.utc(val, "DD-MM-YYYY HH:mm").format();
              }
            });
          }
        }
      } catch (err) {
        console.log("ERROR : ", err);
        this.dateError = "Girdiğiniz tarih formatı hatalı";
      }
    },
  },
};
</script>
